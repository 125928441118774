import './App.css';

function App() {
   return (
      <div className="App">
         <p>Coming soon!</p>
         <p>😊</p>
      </div>
   );
}

export default App;
